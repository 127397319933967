import React from 'react';

const NutrientLevels = props => {
    const nutrientTypeLabel = {
        "salt": "Sel",
        "sugars": "Sucres",
        "saturated-fat": "Acides gras saturés",
        "fat": "Matières grasses"
    }

    const nutrientQuantityLabel = {
        "low": "en faible quantité",
        "moderate": "en quantité modérée",
        "high": "en quantité élevée"
    }

    const nutrientQuantityIcon = {
        "low": ["fa-smile", "has-text-success"],
        "moderate": ["fa-meh", "has-text-warning"],
        "high": ["fa-angry", "has-text-danger"]
    }

    return (
        <p style={{ "fontSize": "12px", "textAlign": "left", width: "100%" }}>
            <span class={`icon ${nutrientQuantityIcon[props.nutrient_level][1]}`}><i class={`fas ${nutrientQuantityIcon[props.nutrient_level][0]}`}></i></span>
            {nutrientTypeLabel[props.nutrient_type]} <span class="is-hidden-mobile">{nutrientQuantityLabel[props.nutrient_level]}</span>
        </p>)

}

const NutriInformation = props => {

    return (
        <div class="columns is-gapless is-mobile w-100">
            <div class="column is-6">
                <NutrientLevels nutrient_level={props.product.nutrient_levels["fat"]} nutrient_type={"fat"} />
                <NutrientLevels nutrient_level={props.product.nutrient_levels["saturated-fat"]} nutrient_type={"saturated-fat"} />
            </div>
            <div class="column is-4">
                <NutrientLevels nutrient_level={props.product.nutrient_levels["sugars"]} nutrient_type={"sugars"} />
                <NutrientLevels nutrient_level={props.product.nutrient_levels["salt"]} nutrient_type={"salt"} />
            </div>
            <div class="column is-2">
                <span class={`icon is-large is-${props.product.nutrition_grade_fr}`}>
                    <span class="is-uppercase" style={{fontSize:"28px"}}>{props.product.nutrition_grade_fr}</span>
                </span>
            </div>
        </div>
    );
};

export default NutriInformation;