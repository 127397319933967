import React, { useState, useEffect } from 'react';
import ProgressBar from './ProgressBar'
import { shareQuizEvent } from '../api/Analytics';
var codec = require('json-url')('lzma');
var base64int = require('base64int')

const EndGame = props => {
    let [shareLink, setShareLink] = useState("");
    let [isModalShareLinkActive, setModalShareLinkActive] = useState("");
    let [isModalNameRequiredActive, setModalNameRequiredActive] = useState("");
    const [shareObj, setShareObj] = useState({
        s: props.score,
        n: "",
        p: props.products.map(product => base64int.encode(product._id))
    });
    let inputLink = null;

    useEffect(() => {
        if (isNameValid()) {
            codec.compress(shareObj).then(result => setShareLink(`${window.location.protocol}//${window.location.host}/game/${props.categoryId}?d=${result}`));
        }
        else {
            setShareLink("")
        }

    }, [shareObj]);

    const isNameValid = () => {
        return shareObj.n.length > 0 && shareObj.n.length < 15
    }

    const copyAction = () => {
        inputLink.select();
        document.execCommand('copy');
    }

    const shareAction = () => {
        if (shareLink === "") {
            setModalNameRequiredActive("is-active")
            return
        }
        shareQuizEvent(props.categoryId, props.score, shareObj.n)

        if (navigator.share) {
            navigator.share({
                title: `NutriGame`,
                text: `${shareObj.n} vous invite à jouer à NutriGame`,
                url: shareLink,
            })
                .then(() => console.log('Successful share'))
                .catch((error) => setModalShareLinkActive("is-active"));
        }
        else {
            setModalShareLinkActive("is-active")
        }
    }

    const congratulationText = () => {
        if (props.rival.name !== undefined && props.rival.name !== "") {
            if (props.score > props.rival.score) {
                return <span><span class="icon has-text-success"><i class="fa fa-smile"></i></span> Félicitation vous avez battu {props.rival.name} !</span>
            }
            else if (props.score < props.rival.score) {
                return <span><span class="icon has-text-danger"><i class="fa fa-frown"></i></span> Dommage {props.rival.name} vous a battu !</span>
            }
            else {
                return <span><span class="icon has-text-warning"><i class="fa fa-meh"></i></span> C'est une égalité avec {props.rival.name} !</span>
            }
        }
        return "J'espère que ce quiz vous a permis d'en apprendre un peu plus sur les produits que nous consommons au quotidien"
    }

    const modalShareLinkContent = () => {
        return (
            <div class={`modal ${isModalShareLinkActive}`}>
                <div class="modal-background"></div>
                <div class="modal-card">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Partager le quiz</p>
                    </header>
                    <section class="modal-card-body">
                        <p>Voici le lien de partage : </p>
                        <br/>
                        <p><input ref={(input) => inputLink = input} class="input shareLinkUrl" type="text" placeholder="Merci de renseigner votre nom pour récupérer le lien" value={shareLink} readOnly={shareLink !== ""} disabled={shareLink === ""} /></p>
                        <br/>
                        <button class="button is-success" onClick={() => copyAction()}>
                            Copier le lien
                        </button>

                    </section>
                    <footer style={{ justifyContent: "center" }} class="modal-card-foot">
                        <button class="button is-info" onClick={() => setModalShareLinkActive("")}>Fermer</button>
                    </footer>
                </div>
            </div>
        )
    }

    const modalNameRequiredContent = () => {
        return (
            <div class={`modal ${isModalNameRequiredActive}`}>
                <div class="modal-background"></div>
                <div class="modal-card">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Partager le quiz</p>
                    </header>
                    <section class="modal-card-body">
                        Merci de bien vouloir rentrer ton nom afin de pouvoir partager ce quiz.
                    </section>
                    <footer style={{ justifyContent: "center" }} class="modal-card-foot">
                        <button class="button is-info" onClick={() => setModalNameRequiredActive("")}>Fermer</button>
                    </footer>
                </div>
            </div>
        )
    }

    return (
        <React.Fragment>
            {modalShareLinkContent()}
            {modalNameRequiredContent()}
            <div class="columns is-multiline is-centered is-mobile">
                <div class="column is-half">
                    <div class="columns is-multiline">
                        <div class="column is-full">
                            <h3 class="title scoreTitle">Mon score final</h3>
                        </div>
                        <div class="column is-full">
                            <ProgressBar valueEnd={props.score * 10} />
                        </div>
                    </div>
                </div>
                {props.rival.name !== undefined && props.rival.name !== "" && <div class="column is-half">
                    <div class="columns is-multiline">
                        <div class="column is-full">
                            <h3 class="title is-capitalized scoreTitle">{props.rival.name}</h3>
                        </div>
                        <div class="column is-full">
                            <ProgressBar valueEnd={props.rival.score * 10} />
                        </div>
                    </div>
                </div>}
            </div>
            <div class="columns is-multiline is-centered">
                <div class="column is-full">
                    {congratulationText()}
                </div>
                <div class="column is-full">
                    Vous pouvez partager ce quiz et défier vos amis
            </div>
                <div class="column is-narrow">
                    <div style={{ marginRight: "15px", width: "215px", display: "inline-block" }} class="control">
                        <input onChange={(event) => setShareObj((prevState) => {
                            if (event.target === null) return prevState
                            return { ...prevState, n: event.target.value }
                        })} class="input shareLinkName" maxLength="14" type="text" placeholder="Entrer mon nom ici" />
                    </div>
                </div>
                <div class="column is-narrow">
                    <div style={{ justifyContent: "center" }} class="field has-addons">
                        <div class="control">
                            <button class="button is-info" onClick={() => shareAction()}>
                                Partager le quiz
                        </button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default EndGame;