const getProductsByCategoryId = (categoryId, productSize, setProducts) => {
    return fetch("https://fr.openfoodfacts.org/cgi/search.pl?action=process&tagtype_0=categories&tag_contains_0=contains&tag_0="+encodeURI(categoryId)+"&json=true&page_size=100")
        .then(response => response.json())
        .then((jsonData) => {
            // jsonData is parsed json object received from url
            const filteredProduits = filterProducts(jsonData.products)
            const products = [];
            for(let i = 0; i < productSize; i++){
                const itemA = filteredProduits.splice(Math.floor(Math.random() * filteredProduits.length), 1)[0];
                let itemB;
                let securityCount = 0;
                while(itemB == undefined && securityCount++ < 10000){
                    const itemToPick = filteredProduits.splice(Math.floor(Math.random() * filteredProduits.length), 1)[0];
                    if(itemToPick.nutrition_grade_fr != itemA.nutrition_grade_fr){
                        itemB = itemToPick
                    }
                    else {
                        filteredProduits.push(itemToPick)
                    }
                }
                products.push(itemA)
                products.push(itemB)
            }
            setProducts(products)
        })
        .catch((error) => {
            // handle your errors here
            console.error(error)
        })
}

const getProductsById = (productIds, setProducts) => {
    Promise.all(productIds.map((id) => getProductById(id))).then((jsonResponses) => {
        setProducts(jsonResponses.map(jsonResponse => jsonResponse.product))
    })
};

const getProductById = (productId) => {
    return fetch("https://world.openfoodfacts.org/api/v0/product/"+productId+".json")
        .then(response => response.json())
};

const filterProducts = (products) => {
    return products.filter(product => {
        console.log(product.nutrient_levels)
        return product.nutrition_grade_fr != undefined && product.product_name_fr != undefined && product.product_name_fr != "" && product.selected_images.front != undefined && product.selected_images.front.display != undefined && product.selected_images.front.display.fr != undefined 
        && product.nutrient_levels != undefined && product.nutrient_levels.fat != undefined
    })
}



export {getProductsByCategoryId, getProductsById}