import React from 'react';
import './App.css';
import GameBoard from './component/GameBoard';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Home from './component/Home';
import 'bulma/css/bulma.min.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import About from './component/About';

function App() {
  return (

    <React.Fragment>
      <nav class="navbar is-success">
        <div class="navbar-brand">
          <a class="navbar-item" href="/">

            <img src="/img/nutrigame-monochrome-white.svg" alt="NutriGame" height="28" width="160" />

          </a>
          <span class="navbar-item is-italic is-hidden-mobile">
            Le jeu pour en apprendre un peu plus sur les bons et les mauvais produits
      </span>

        </div>

        <div id="navMenuColorsuccess-example" class="navbar-menu">
          <div class="navbar-end">
            <a class="navbar-item" href="/about">
              À propos
            </a>
            <div class="navbar-item">
              <p class="control">
                <a class="button" href="/">
                  <span class="icon">
                    <i class="fa fa-gamepad"></i>
                  </span>
                  <span>
                    Nouvelle Partie
              </span>
                </a>
              </p>
            </div>
          </div>
        </div>
      </nav>
      <section style={{ minHeight: "calc(100vh - 3.5rem)" }} class="App hero is-fullheight-with-navbar">
        <div class="hero-body">
          <div class="container" style={{maxWidth: "100%"}}>
            <Router>
              <Switch>
              <Route path="/about">
                  <About />
                </Route>
                <Route path="/game/:categoryId">
                  <GameBoard />
                </Route>
                <Route path="/">
                  <Home />
                </Route>
              </Switch>

            </Router>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default App;
