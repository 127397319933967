import React from 'react';
import NutriInformation from './NutriInformation';


const ProductInformation = props => {

    const getPanelClass = () => {
        let panelClass = ""
        if(props.showAnswer){
            panelClass = `showAnswer is-${props.product.nutrition_grade_fr}`
        }
        return panelClass
    }

    console.log(props.answerResult)
    if (props.product === undefined || props.product._id === undefined) {
        return null;
    }
    return (
        <article class={`panel h-100 answerDiv ${getPanelClass()}`} onClick={() => props.answerAction(props.product)}>
            <p class="panel-heading">
            {props.product.product_name_fr}
            </p>
            <div class="panel-block panelImage" style={{ height: "40vh" }}>
                <img className={"productInfo"} src={props.product.selected_images.front.display.fr} />

            </div>
            <div class="panel-block" style={{ height: "60px" }}>
            <div class="choiceInfo w-100" style={{display: "none"}}>Je pense que tu es le plus sain <span class="icon has-text-warning"><i class="fas fa-grin-hearts"></i></span></div>
                {props.showAnswer &&
                    <NutriInformation product={props.product} />
                }
            </div>
        </article>
    );
};

export default ProductInformation;