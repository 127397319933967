import React, { useState, useEffect } from 'react';
import ProductInformation from './ProductInformation';
import { getProductsByCategoryId, getProductsById } from '../api/OpenFoodFacts';
import { startQuizEvent, endQuizEvent } from '../api/Analytics';
import {
    useParams, useLocation
} from "react-router-dom";
import EndGame from './EndGame';
import categories from '../data/categoriesFiltered.json';
var codec = require('json-url')('lzma');
var base64int = require('base64int')

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const GameBoard = props => {
    const pickProducts = (questionNumber) => {
        if (products.length === 0) return;
        const index = (questionNumber - 1) * 2
        setProductA(products[index])
        setProductB(products[index + 1])
    }

    const answerAction = (productAnswer) => {
        if(answerResult != "") return ;
        if (productAnswer.nutriscore_score <= productA.nutriscore_score && productAnswer.nutriscore_score <= productB.nutriscore_score) {
            setAnswerResult("true")
            setScore(score + 1)
        }
        else {
            setAnswerResult("false")
        }
    }

    const answerText = () => {
        if (answerResult === "") return ""
        let h2 = ""
        let i = ""
        console.log(answerResult)
        if (answerResult === "true") {
            h2 = <h2 class="answerTip has-text-success">Bonne réponse</h2>
            i = <i class="has-text-success fas fa-thumbs-up"></i>
        }
        else {
            h2 = <h2 class="answerTip has-text-danger">Mauvaise réponse</h2>
            i = <i class="has-text-danger fas fa-thumbs-down"></i>
        }

        return (<li>
            <span class="icon is-hidden-mobile">
                {i}
            </span>
            <a href="#">{h2}</a></li>)
    }

    const progressBarColor = () => {
        if (answerResult === "true") return "is-success"
        if (answerResult === "false") return "is-danger"
        return ""
    }

    const nextQuestion = () => {
        setLoading(true)
        pickProducts(questionNumber + 1)
        setQuestionNumber(questionNumber + 1)
        setAnswerResult("")

    }

    const initGame = () => {
        startQuizEvent(categoryId, rival)
        nextQuestion()
    }

    const finishGame = () => {
        endQuizEvent(categoryId, score, rival)
        setGameFinished(true)
    }

    const modalRivalContent = () => {
        if (rival.name === undefined || rival.name === "") return ""
        return (
            <div class={`modal ${isModalInfoActive}`}>
                    <div class="modal-background"></div>
                    <div class="modal-card">
                        <header class="modal-card-head">
                            <p class="modal-card-title">{rival.name} vous a lancé un défi</p>
                        </header>
                        <section class="modal-card-body">
                            <p class="title">En quoi consiste le jeu :</p>
                            <p class="subtitle">Une série de dix face à face de produits va vous être proposée</p>
                            <div class="content">
                                <div class="columns is-multiline">
                                    <div class="column is-full">
                                        <p><span class="icons"><i style={{ verticalAlign: "middle", marginRight: "10px" }} class="fas fa-2x fa-arrow-circle-right"></i></span><span class="has-text-weight-bold">Il faut trouver quel est le produit avec le meilleur Nutri-Score</span></p>
                                    </div>
                                    <div class="column is-full">
                                        <p style={{ fontSize: "0.9em" }}>
                                            Le Nutri-Score indique la qualité nutritionnelle des aliments avec des notes allant de A à E. </p>
                                        <div>
                                            <img style={{ width: "100px" }} src="https://static.openfoodfacts.org/images/misc/nutriscore-a.png" alt="NutriScore" />
                                        </div>
                                    </div>
                                    <div class="column is-full">
                                        <p class="subtitle">La catégorie du quiz est : <span class="has-text-weight-bold">{getCategoryLabel()}</span></p>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <footer style={{justifyContent:"center"}} class="modal-card-foot">
                            <button class="button is-success" onClick={()=> setModalInfoActive("")}>Commencer à jouer</button>
                        </footer>
                    </div>
                </div>
        )
    }

    const getCategoryLabel = () =>  {
        return categories.find((category => category.value == categoryId)).label
    }

    const numberOfQuestion = 10

    let { categoryId } = useParams();
    let query = useQuery();


    let [answerResult, setAnswerResult] = useState("");
    let [questionNumber, setQuestionNumber] = useState(0);
    let [score, setScore] = useState(0);
    let [gameFinished, setGameFinished] = useState(false);
    const [products, setProducts] = useState([]);
    const [productA, setProductA] = useState({});
    const [productB, setProductB] = useState({});
    const [rival, setRival] = useState({ name: "", score: 0 });
    let [isModalInfoActive, setModalInfoActive] = useState("is-active");
    let [isLoading, setLoading] = useState(false);

    useEffect(() => {
        if (products.length > 0) initGame();
    }, [products]);

    useEffect(() => {
        setLoading(false)
    }, [productB]);

    useEffect(() => {
        if (query.get("d") != undefined) {
            codec.decompress(query.get("d")).then(shareObj => {
                setRival({ name: shareObj.n, score: shareObj.s })
                getProductsById(shareObj.p.map((id64) => base64int.decode(id64)), setProducts)
            })
        }
        else {
            getProductsByCategoryId(categoryId, numberOfQuestion, setProducts)
        }
    }, []);

    if (!gameFinished) {
        return (
            <React.Fragment>
                {modalRivalContent()}
                 <div class="columns">
                    <div class="column is-full">
                        <h4 class="title titleTips is-4">Choisis le produit qui te paraît le plus sain</h4>
                        <progress class={`is-hidden-mobile progress ${progressBarColor()}`} value={questionNumber > 0 ? questionNumber - 1 : undefined} max="9"></progress>
                    </div>
                </div>

                <div class="columns">
                    <div class="column is-half h-100">
                        <ProductInformation product={productA} showAnswer={answerResult !== ""} answerAction={answerAction} />
                    </div>
                    <div class="column is-half h-100">
                        <ProductInformation product={productB} showAnswer={answerResult !== ""} answerAction={answerAction} />
                    </div>
                    {isLoading && <div class="loading-overlay is-active"><div class="loading-background"></div> <div class="loading-icon"></div></div>}
                </div>

                <nav class="breadcrumb is-large has-arrow-separator is-centered" aria-label="breadcrumbs" style={{ height: "40px" }}>
                    <ul>
                        {answerText()}
                        <li>{answerResult !== "" && numberOfQuestion !== questionNumber &&
                            <button class="button is-light" onClick={nextQuestion}>Question Suivante</button>
                        }
                            {answerResult !== "" && numberOfQuestion === questionNumber &&
                                <button class="button is-light" onClick={() => finishGame()}>Voir le score final</button>
                            }</li>
                    </ul>
                </nav>

            </React.Fragment >
        );
    }
    else {
        return <div>
            <EndGame categoryId={categoryId} products={products} score={score} rival={rival} />
        </div>
    }

};





export default GameBoard;