import React, { useState } from 'react';

const About = props => {
    let [status, setStatus] = useState("");

    const submitForm = (ev) => {
        ev.preventDefault();
        const form = ev.target;
        const data = new FormData(form);
        const xhr = new XMLHttpRequest();
        xhr.open(form.method, form.action);
        xhr.setRequestHeader("Accept", "application/json");
        xhr.onreadystatechange = () => {
          if (xhr.readyState !== XMLHttpRequest.DONE) return;
          if (xhr.status === 200) {
            form.reset();
            setStatus("SUCCESS");
          } else {
            setStatus("ERROR");
          }
        };
        xhr.send(data);
      }

    return <React.Fragment>
        <div class="columns is-multiline">
            <div class="column is-full">
                Créé par <a href="mailto:alexandre.patelli@outlook.com">Alexandre Patelli</a> dans le but de sensibiliser sur les produits alimentaires de notre quotidien.
            </div>
            <div class="column is-full">
                Les données utilisées par l'appliation proviennent de la base de donnée collaborative <a href="https://world.openfoodfacts.org/">OpenFoodFacts</a>
            </div>
        </div>
        <section class="section">
            <div class="container">
                <div class="columns is-centered">
                    <div class="column is-half">
                        <h1 class="title">Formulaire de contact</h1>
                        <form
                            onSubmit={submitForm}
                            action="https://formspree.io/xeqzbenp"
                            method="POST"
                        >
                            <div class="field">
                                <div class="control">
                                    <input
                                        class="input"
                                        id="email"
                                        type="email"
                                        name="email"
                                        placeholder="Email"
                                        required
                                    />
                                </div>
                            </div>

                            <div class="field">
                                <div class="control">
                                    <textarea
                                        class="textarea"
                                        id="message"
                                        name="message"
                                        placeholder="Message"
                                        required
                                    ></textarea>
                                </div>
                            </div>
                            <div class="field is-grouped">
                                <div class="control">
                                {status === "SUCCESS" ? <p>Merci !</p> : <button class="button is-link">Envoyer le message</button>}
        {status === "ERROR" && <p>Ooops! There was an error.</p>}
                                    
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </React.Fragment>
};

export default About;