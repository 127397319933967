const logEvent = (name, eventObject) => {
    if (window.firebase !== undefined) {
        window.firebase.analytics().logEvent(name, eventObject);
    }
}

const startQuizEvent = (categoryId, rival) => {
    const eventObject = {
        "category": categoryId
    }
    if (rival !== undefined) {
        if (rival.name !== undefined && rival.name !== "") {
            eventObject.rivalName = rival.name
            eventObject.rivalScore = rival.score
        }
    }
    logEvent('start_quiz', eventObject);
}

const endQuizEvent = (categoryId, score, rival) => {
    const eventObject = {
        "category": categoryId,
        "score": score
    }
    if (rival !== undefined) {
        if (rival.name !== undefined && rival.name !== "") {
            eventObject.rivalName = rival.name
            eventObject.rivalScore = rival.score
        }
    }
    logEvent('end_quiz', eventObject);
}

const shareQuizEvent = (categoryId, score, name) => {
    const eventObject = {
        "category": categoryId,
        "score": score,
        "name": name
    }
    logEvent('share_quiz', eventObject);
}




export { startQuizEvent, endQuizEvent, shareQuizEvent }