import React, { useState } from 'react';
import {
    Link
} from "react-router-dom";
import Select from 'react-select'
import categories from '../data/categoriesFiltered.json';
const Home = props => {

    let [categoryChoice, setCategoryChoice] = useState(categories[Math.floor(Math.random() * categories.length)].value);



    const handleSelectChange = selectedOption => {
        setCategoryChoice(selectedOption.value)
    }

    return (
        <div class="columns is-multiline">
            <div class="column is-8 is-offset-2">
                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical">
                        <article class="tile is-child notification">
                            <div class="content">
                                <p class="title">En quoi consiste le jeu :</p>
                                <p class="subtitle">Une série de dix face à face de produits va vous être proposée</p>
                                <div class="content">
                                    <div class="columns is-multiline">
                                        <div class="column is-full">
                                            <p><span class="icons"><i style={{ verticalAlign: "middle", marginRight: "10px" }} class="fas fa-2x fa-arrow-circle-right"></i></span><span class="has-text-weight-bold">Il faut trouver quel est le produit avec le meilleur Nutri-Score</span></p>
                                        </div>
                                        <div class="column is-full">
                                            <p>
                                                Le Nutri-Score indique la qualité nutritionnelle des aliments avec des notes allant de A à E. </p>
                                                <div>
                                                <img style={{ width: "100px" }} src="https://static.openfoodfacts.org/images/misc/nutriscore-a.png" alt="NutriScore" />
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </article>
                        <article class="tile is-child notification">
                            <div class="content">
                                <p class="title">Tu veux jouer ?</p>
                                <p class="subtitle">Choisi d'abord une catégorie puis lance la partie</p>
                                <div class="content">
                                    <Select placeholder="Choisir une catégorie de produits" options={categories} onChange={handleSelectChange} isSearchable={false}/>
                                    <Link style={{ marginTop: "15px" }} class="button is-success" to={"/game/" + categoryChoice}>Lancer le jeu</Link>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Home;