import React from 'react';
import { Animate } from "react-move";
import { easeQuadInOut } from 'd3-ease';
import {
    CircularProgressbar,
    buildStyles
  } from "react-circular-progressbar";
  import "react-circular-progressbar/dist/styles.css";

class AnimatedProgressProvider extends React.Component {
  interval = undefined;

  state = {
    isAnimated: false
  };

  static defaultProps = {
    valueStart: 0
  };

  componentDidMount() {
    if (this.props.repeat) {
      this.interval = window.setInterval(() => {
        this.setState({
          isAnimated: !this.state.isAnimated
        });
      }, this.props.duration * 1000);
    } else {
      this.setState({
        isAnimated: !this.state.isAnimated
      });
    }
  }

  componentWillUnmount() {
    window.clearInterval(this.interval);
  }

  render() {
    return (
      <Animate
        start={() => ({
          value: this.props.valueStart
        })}
        update={() => ({
          value: [
            this.state.isAnimated ? this.props.valueEnd : this.props.valueStart
          ],
          timing: {
            duration: this.props.duration * 1000,
            ease: this.props.easingFunction
          }
        })}
      >
        {({ value }) => this.props.children(value)}
      </Animate>
    );
  }
}


const ProgressBar = props => {
    return <div style={{width: "30vh", margin:"auto", maxWidth: "100%"}}><AnimatedProgressProvider
    valueStart={0}
    valueEnd={props.valueEnd}
    duration={3}
    easingFunction={easeQuadInOut}
  >
    {(value) => {
      return (
        <CircularProgressbar
          value={value}
          text={`${parseInt(value/10)}/10`}
          /* This is important to include, because if you're fully managing the
          animation yourself, you'll want to disable the CSS animation. */
          styles={buildStyles({ pathTransition: 'none' })}
        />
      );
    }}
  </AnimatedProgressProvider>
  </div>;
};

export default ProgressBar;